<template>
    <v-dialog v-model="visible" persistent width="550px">
        <v-card>
            <v-card-title class="justify-center">
                <v-switch
                    dense
                    inset
                    hide-details
                    class="pb-2"
                    v-model="form.ativo"
                    :label="form.ativo ? 'Ativo' : 'Inativo'"
                />
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Bairro</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                style="height: 200px;"
            >
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            @keypress.enter="$refs.valor.focus()"
                            ref="descricao"
                            label="Nome"
                            v-model="form.descricao"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="valor"
                            label="Taxa de entrega"
                            prefix="R$"
                            v-model="form.valor"
                            @keypress.enter="salvar"
                            placeholder="0.00"
                            v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                         <v-autocomplete
                            prepend-inner-icon="mdi-magnify"
                            v-model="form.municipio_id"
                            :items="municipios"
                            dense
                            clearable
                            item-text="descricao"
                            item-value="id"
                            label="Município (opcional)"
                            outlined
                        >
                            <!-- <template #append-item>
                                <div
                                    style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                    @click="newMunicipio"
                                >
                                    <v-icon>mdi-plus</v-icon> Cadastrar novo município
                                </div>
                            </template> -->
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'Bairro',

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        form: {
            ativo: true,
            descricao: '',
            valor: '',
            municipio_id: null,
        },
        municipios: [],
    }),

    mounted() {
        this.fillMunicipios();
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        },

        visible(v) {
            if (!v) {
                return;
            }

            setTimeout(() => this.$refs.descricao.focus(), 100);
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            const id = this.form.id;

            if (!this.form.descricao) {
                this.notify('Preencha o nome', 'warning');
                return;
            }

            this.form.valor = this.form.valor || 0;

            if (id) {
                this.loading = true;
                this.$http.put(`bairros/${id}`, this.form).then(resp => {
                    const data = resp.data;
                    if (data.type == 'warning') {
                        this.notify(data.msg, 'warning');
                        return;
                    }
                    this.$emit('onCadastro');
                    this.$emit('close');
                    this.setDashboardInitialized(false);
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('bairros', this.form).then(({ data }) => {
                this.$emit('onCadastro');
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
                this.reset(data.data);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        fillMunicipios() {
            this.municipios = [];
            this.$http.get('municipios').then(resp => {
                this.municipios = resp.data.data;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        reset() {
            this.form = {
                ativo: true,
                id: '',
                descricao: '',
                valor: '',
                municipio_id: null,
            };
            this.$nextTick(() => this.$refs.descricao.focus());
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
