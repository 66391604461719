<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Bairros</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <ButtonAddNew @click="openCadastro"/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="descricao"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <template v-slot:item.valor="{ item }">
                                <span style="color: green;">R$&nbsp;{{ formatPrice(item.valor) }}</span>
                            </template>
                            <template v-slot:item.ativo="{ item }">
                                <v-switch
                                    v-model="item.ativo"
                                    inset
                                    hide-details
                                    dense
                                    @click.stop
                                    @change="onUpdateAtivo(item)"
                                    style="margin-top: -4px;"
                                />
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon @click.stop="excluir(item.id)" color="red">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
            <Bairro
                :visible="visible"
                :formData="form"
                @onCadastro="onCadastro"
                @close="close"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import Bairro from '@/pages/cadastro/Bairro';

export default {
    name: 'Bairros',

    components: {
        BaseContainer,
        ButtonAddNew,
        Bairro,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        headers: [
            { text: 'Nome', value: 'descricao' },
            { text: 'Valor', value: 'valor' },
            { text: 'Ativo', value: 'ativo' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        form: {},
        visible: false,
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            this.$http.get('bairros').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        close() {
            this.visible = false;
            this.form = { ativo: true };
        },

        openCadastro() {
            this.form = { ativo: true };
            this.visible = true;
        },

        onCadastro() {
            this.consultar();
        },

        editar(row) {
            this.form = row;
            this.visible = true;
        },

        excluir(id) {
            if (confirm('Deseja realmente excluir?')) {
                this.$http.delete(`bairros/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },

        onUpdateAtivo(item) {
            this.$http.put(`bairros/${item.id}`, item).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },
    },
}
</script>
